<template>
  <div>
    <div v-if="mode === 'view'" class="header">
      <div class="title">
        <span class="main">{{ lbl['fgf-campaigndata'] }}</span>
        <span class="sub">{{ lbl['fgf-campaigndatasub'] }}</span>
      </div>
      <div class="activeFGF">
        <a-switch
          id="friendgetfriends_mainform_changestatusfgf_button"
          :checked="isActive"
          @click="changeStatusFGF"
        />
        {{ lbl['fgf-activecampaign'] }}
      </div>
    </div>
    <div v-if="mode === 'view' || mode === 'history'" class="stat">
      <div
        v-for="(item, index) in statsBoxData"
        :key="index"
        class="statsBox"
        @click="showTableList(item)"
      >
        <div class="title">
          <span :style="{ backgroundColor: item.bg }">
            <img
              :src="require('@/assets/images/' + item.icon + '.svg')"
              alt=""
              width="24"
              height="24"
            /> </span
          >{{ lbl['fgf-' + item.title] }}
        </div>
        <div class="value">
          <div>
            <span :class="['number', item.title]">{{ item.value }}</span>
            <span class="unit">{{ lbl['fgf-' + item.unit] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mainContainer">
      <div class="mainInputForm">
        <div class="nFlex imgCover">
          <div class="label">{{ lbl['fgf-imgcover'] }}</div>
          <div class="mainInput">
            <div class="previewImg">
              <img
                v-if="!imgPreview"
                src="@/assets/images/empty_fgf.png"
                alt=""
              />
              <img v-else :src="imgPreview" alt="" />
            </div>
            <div class="upload">
              <div>
                <button
                  id="friendgetfriends_mainform_uploadartwork_button"
                  :disabled="mode === 'view' || mode === 'history'"
                  @click="uploadArtWork($event)"
                  class="mainBtn"
                >
                  <img
                    :src="
                      require(`@/assets/images/${
                        mode === 'view' || mode === 'history'
                          ? 'uploaddisabled'
                          : 'upload'
                      }.svg`)
                    "
                    alt=""
                    width="24"
                    height="24"
                  />
                  {{ lbl['fgf-uploadart'] }}
                </button>
                <input
                  @change="onImgFileChange($event)"
                  ref="uploadImg"
                  type="file"
                  accept="image/jpeg, image/png, image/jpg"
                  hidden
                />
              </div>
              <div class="fontcolor maxwidth250px">
                <p>{{ lbl['fgf-uploadinfo'].split('_')[0] }}</p>
                <p>{{ lbl['fgf-uploadinfo'].split('_')[1] }}</p>
                <p>{{ lbl['fgf-uploadinfo'].split('_')[2] }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="nFlex title">
          <div class="label">
            {{ lbl['fgf-title'] }}
            <a-tooltip placement="bottom">
              <template #title>{{ lbl['fgf-titleinfo'] }}</template>
              <img :src="require('@/assets/images/info.png')" alt="" />
            </a-tooltip>
          </div>
          <div class="mainInput">
            <input
              id="friendgetfriends_mainform_inputname_input"
              :disabled="mode === 'view' || mode === 'history'"
              type="text"
              maxlength="200"
              v-model="data.name"
            />
            <div class="totalText">
              {{
                data.name
                  ? data.name.replace(/(\r\n|\n|\r)/g, '  ').length
                  : ''
              }}/200
            </div>
          </div>
        </div>
        <div class="nFlex detail">
          <div class="label">
            {{ lbl['fgf-details'] }}
            <a-tooltip placement="bottom">
              <template #title>{{ lbl['fgf-detailsinfo'] }}</template>
              <img :src="require('@/assets/images/info.png')" alt="" />
            </a-tooltip>
          </div>
          <div class="mainInput">
            <textarea
              id="friendgetfriends_mainform_inputdescription_textarea"
              :disabled="mode === 'view' || mode === 'history'"
              rows="4"
              type="text"
              :maxlength="'500'"
              v-model="data.description"
            />
            <div class="totalText">
              {{
                data.description
                  ? data.description.replace(/(\r\n|\n|\r)/g, '  ').length
                  : ''
              }}/500
            </div>
          </div>
        </div>
        <div class="nFlex period">
          <div class="label">{{ lbl['fgf-period'] }}</div>
          <div class="mainInput d-flex justify-content-between">
            <a-date-picker
              :disabled="mode === 'view' || mode === 'history'"
              :disabled-date="disabledStartDate"
              format="DD/MM/YYYY"
              v-model="data.date[0]"
            />
            <span class="totextlable">{{ lbl['pagination-to-text'] }}</span>
            <a-date-picker
              :disabled="mode === 'view' || mode === 'history' || !data.date[0]"
              :disabled-date="disabledEndDate"
              format="DD/MM/YYYY"
              v-model="data.date[1]"
            />
          </div>
        </div>
        <div class="nFlex referal">
          <div class="label">{{ lbl['fgf-refget'] }}</div>
          <div class="mainInput flexwarp">
            <div class="d-flex">
              <input
                id="friendgetfriends_mainform_inputreferralpoints_input"
                :disabled="mode === 'view' || mode === 'history'"
                @input="checkPoint($event, 'referral_points')"
                type="text"
                v-model="data.referral_points"
              />
              <div class="unit totextlable">{{ lbl['fgf-point'] }}</div>
            </div>
            <div class="d-flex flexwarp">
              <div class="totextlable">{{ lbl['fgf-newget'] }}</div>
              <input
                id="friendgetfriends_mainform_inputuserpoints_input"
                :disabled="mode === 'view' || mode === 'history'"
                @input="checkPoint($event, 'user_points')"
                type="text"
                v-model="data.user_points"
              />
              <div class="unit totextlable">{{ lbl['fgf-point'] }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="nFlex referal">
          <div class="label">{{ lbl['fgf-newget'] }}</div>
          <div class="mainInput">
            <input
              :disabled="mode === 'view' || mode === 'history'"
              @input="checkPoint"
              @keypress="isNumber($event)"
              type="text"
              v-model="data.user_points"
            />
            <div class="unit">{{ lbl['fgf-point'] }}</div>
          </div>
        </div> -->
        <div class="nFlex maxPoint">
          <div class="label">{{ lbl['fgf-maxaccount'] }}</div>
          <div class="mainInput">
            <a-radio-group
              class="radiogrup"
              :disabled="mode === 'view' || mode === 'history'"
              :value="maxPoint"
              @change="checkLimit($event)"
            >
              <a-radio :value="'Unlimit'">{{ lbl['fgf-unlimit'] }}</a-radio>
              <a-radio :value="'Limit'"
                >{{ lbl['fgf-limit'] }}
                <input
                  class="mx-2"
                  :disabled="mode === 'view' || mode === 'history'"
                  v-if="maxPoint === 'Limit'"
                  @input="checkaccPoint($event, 'max_account')"
                  v-model="data.max_account"
                />
                <span v-if="maxPoint === 'Limit'">
                  {{ lbl['fgf-accountwithpointget'] }}
                </span>
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="nFlex message">
          <div class="label">{{ lbl['fgf-invitemessage'] }}</div>
          <div class="mainInput">
            <div
              id="friendgetfriends_mainform_cleartext_div"
              class="cleartext"
              v-if="mode === 'create' || mode === 'edit'"
              @click="clearText"
            >
              {{ lbl['fgf-clear'] }}
            </div>
            <a-textarea
              id="friendgetfriends_mainform_inputinvitemessage_textarea"
              :disabled="mode === 'view' || mode === 'history'"
              type="text"
              :maxLength="200"
              v-model="data.invite_message"
            />
            <div class="totalText invite">
              <span class="fontcolor">{{ lbl['fgf-reflink'] }}</span
              ><span
                >{{
                  data.invite_message
                    ? data.invite_message.replace(/(\r\n|\n|\r)/g, '  ').length
                    : ''
                }}/200</span
              >
            </div>
          </div>
        </div>
        <div class="nFlex condition">
          <div class="label">{{ lbl['fgf-refcondition'] }}</div>
          <div class="mainInput">
            <div class="conditioncontainer">
              <a-checkbox
                v-model="data.validateEarnAndRedeem"
                :disabled="mode === 'view' || mode === 'history'"
              >
                {{ lbl['fgf-refconditionuseto'] }}
              </a-checkbox>
            </div>
            <div class="conditioncontainer">
              <a-checkbox
                v-model="data.joinDaysState"
                @input="checkconditionstate()"
                :disabled="mode === 'view' || mode === 'history'"
              >
                {{ lbl['fgf-refconditionday'] }}
              </a-checkbox>
              <input
                class="mx-2"
                :disabled="
                  mode === 'view' || mode === 'history' || !data.joinDaysState
                "
                @input="checkaccPoint($event, 'day')"
                v-model="data.joinDays"
              />
              <span>{{ lbl['fgf-day'] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="mode !== 'view' && mode !== 'history'"
        class="mainPreviewContainer"
      >
        <div class="previewText">{{ lbl['fgf-preview'] }}</div>
        <div class="imgContainer">
          <img src="@/assets/images/mobile.png" class="img-mobile" alt="img" />
          <div class="previewMaincontainer">
            <div class="storeName dotAfter">{{ storeName }}</div>
            <div class="previewImg">
              <img
                v-if="!imgPreview"
                src="@/assets/images/empty_fgf.png"
                alt=""
              />
              <img v-else :src="imgPreview" alt="" />
            </div>
            <div class="details">
              <div class="title dotAfter">
                {{ data.name ? data.name : '-' }}
              </div>
              <div class="subdetail dotAfter4">
                {{ data.description ? data.description : '-' }}
              </div>
              <div class="date">
                Expire On
                {{ data.date ? formatDateString(this.data.date[1]) : '' }}
              </div>
            </div>
            <div class="invite">
              <div class="header">
                <span class="title">Invite Friend</span>
                <span class="subtitle">How its work?</span>
              </div>
              <div class="lineBtnCintainer">
                <button class="lineBtn">Invite Friend Via LINE</button>
              </div>
              <div class="link">
                <div class="text">
                  or copy link and send to your friend ptivately
                </div>
                <div class="linkContainer">
                  <div class="linktext">
                    https://
                    <button>Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mode !== 'view'" class="btnContainer">
      <button
        id="friendgetfriends_mainform_createfgf_button"
        v-if="mode === 'create'"
        @click="createFGF(data)"
        :disabled="!formState"
        class="mainBtn sec"
      >
        {{ lbl['fgf-save'] }}
      </button>
      <button
        id="friendgetfriends_mainform_onactioneditmodal_button"
        v-if="mode === 'edit'"
        @click="onActionEditModal(true, false)"
        :disabled="!formState"
        class="mainBtn sec"
      >
        {{ lbl['fgf-save'] }}
      </button>
    </div>
    <Modal
      :title="lbl['fgf-pleaseconfirm']"
      :details="lbl['fgf-maximampoint']"
      :backto="lbl['fgf-backto']"
      :isOpenModal="isModalMaxPoint"
      :type="'referral_points'"
      :onActionModal="onActionPointModal"
    />
    <Modal
      :title="lbl['fgf-pleaseconfirm']"
      :details="lbl['fgf-maximampoint']"
      :backto="lbl['fgf-backto']"
      :isOpenModal="isModalMaxPointNew"
      :type="'user_points'"
      :onActionModal="onActionPointModal"
    />
    <Modal
      :title="lbl['fgf-edittitle']"
      :details="lbl['fgf-editsubtitle']"
      :backto="lbl['fgf-backto']"
      :isOpenModal="isModaledit"
      :onActionModal="onActionEditModal"
    />
    <Modal
      :title="lbl['fgf-erroeupload']"
      :details="lbl['fgf-erroeuploadtext']"
      :backto="lbl['fgf-backto']"
      :isOpenModal="isModalUpload"
      :showbackto="false"
      :onActionModal="onActionUploadModal"
    />

    <ModalListNewUser
      v-if="isModalNewUser"
      :row-key="rowKey"
      @closeModal="closeModal($event)"
    />
    <ModalListInviter
      v-if="isModalInviter"
      :row-key="rowKey"
      @closeModal="closeModal($event)"
    />
    <ModalListInvitedPeople
      v-if="isModalInvitedPeople"
      :row-key="rowKey"
      @closeModal="closeModal($event)"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Modal from './FGFModal'
import moment from 'moment'
import ModalListNewUser from '../component/ModalListNewUser'
import ModalListInviter from '../component/ModalListInviter'
import ModalListInvitedPeople from '../component/ModalListInvitedPeople'

export default {
  name: 'FriendGetFriendsMian',
  components: {
    Modal,
    ModalListNewUser,
    ModalListInviter,
    ModalListInvitedPeople,
  },
  mixins: [Mixin],
  props: {
    mode: {
      type: String,
    },
    onCreate: {
      type: Function,
    },
    checkLimit: {
      type: Function,
    },
    updateFGF: {
      type: Function,
    },
    changeStatusFGF: {
      type: Function,
    },
    data: {
      type: Object,
      default() {
        return {
          name: '',
          description: '',
          image: '',
        }
      },
    },
    id: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
    storeName: {
      type: String,
    },
    maxPoint: {
      type: String,
      default: 'Unlimit',
    },
    imgPreview: {
      type: String,
      default: '',
    },
    statsBoxData: {
      type: Array,
    },
    rowKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    imgState() {
      return this.data.image
    },
    titleState() {
      return this.data.name
    },
    detailState() {
      return this.data.description
    },
    dateState() {
      return this.data.date[0]
    },
    joinDateState() {
      return this.data.joinDays > 0
    },
    pointState() {
      return (
        !isNaN(parseInt(this.data.referral_points)) &&
        !isNaN(parseInt(this.data.user_points))
      )
    },
    limitState() {
      if (this.maxPoint === 'Limit') {
        return parseInt(this.data.max_account) > 0
      }
      return !isNaN(parseInt(this.data.max_account))
    },
    inviteState() {
      return this.data.invite_message
    },
    formState() {
      if (this.mode === 'edit') {
        return (
          this.titleState &&
          this.detailState &&
          this.dateState &&
          this.pointState &&
          this.limitState &&
          this.inviteState
        )
      }
      return (
        this.titleState &&
        this.detailState &&
        this.dateState &&
        this.pointState &&
        this.limitState &&
        this.inviteState
        // && this.imgState
      )
    },
  },
  data() {
    return {
      image: [],
      isModalMaxPoint: false,
      isModalMaxPointNew: false,
      isModaledit: false,
      isModalUpload: false,
      isAcceptPoint: false,
      isAcceptPointNew: false,
      startValue: null,
      endValue: null,
      isModalNewUser: false,
      isModalInviter: false,
      isModalInvitedPeople: false,
    }
  },
  created() {
    this.handleFooter(true)
  },
  mounted() {
    this.disabledStartDate(moment(new Date()))
  },
  methods: {
    coglog() {
      console.log(this.data)
      console.log(moment(this.data.date[0]).format('yyyy-MM-DD'))
      console.log(moment(this.data.date[1]).format('yyyy-MM-DD'))
    },
    clearText() {
      this.data.invite_message = ''
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    formatDateString(dateString, format = 'DD MMM YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    disabledStartDate(startValue) {
      this.startValue = startValue
      var date = new Date()
      if (!this.data.date[1]) {
        return startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return (
        startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf() ||
        startValue.valueOf() > this.data.date[1].valueOf()
      )
    },
    disabledEndDate(endValue) {
      this.endValue = endValue
      var date = new Date()
      if (!this.data.date[0]) {
        return endValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return (
        endValue.valueOf() < this.data.date[0] ||
        this.startValue.valueOf() >= endValue.valueOf()
      )
    },
    onActionPointModal(state, confirm, type) {
      if (type == 'referral_points') {
        this.isModalMaxPoint = state
      } else {
        this.isModalMaxPointNew = state
      }
      if (!confirm && type == 'referral_points') {
        this.data.referral_points = 1
      } else if (type == 'referral_points') {
        this.isAcceptPoint = true
      }
      if (!confirm && type == 'user_points') {
        this.data.user_points = 0
      } else if (type == 'user_points') {
        this.isAcceptPointNew = true
      }
    },
    onActionUploadModal(state) {
      this.isModalUpload = state
    },
    onActionEditModal(state, confirm) {
      this.isModaledit = state
      if (confirm) {
        this.data.startdate = moment(this.data.date[0]).format('yyyy-MM-DD')
        this.data.expiredate = moment(this.data.date[1]).format('yyyy-MM-DD')
        this.updateFGF(this.data)
      }
    },
    checkaccPoint(e, type) {
      this.data[type] = e.target.value.replace(/[^0-9]/g, '')
      // if (type == 'day' && this.data.joinDays <= 0) {
      //   this.data.joinDays = 1
      // }
    },
    checkconditionstate() {
      console.log('test')
      if (!this.data.joinDaysState) {
        this.data.joinDays = 0
      } else {
        if (this.data.joinDays == 0) {
          this.data.joinDays = 1
        }
      }
    },
    checkPoint(e, type) {
      this.data[type] = e.target.value.replace(/[^0-9]/g, '')
      if (!this.isAcceptPoint) {
        if (parseInt(this.data.referral_points) >= 10) {
          this.isModalMaxPoint = true
        }
      }
      if (!this.isAcceptPointNew) {
        if (parseInt(this.data.user_points) >= 10) {
          this.isModalMaxPointNew = true
        }
      }
    },
    uploadArtWork() {
      if (this.$route.query.mode !== 'view') {
        this.$refs.uploadImg.click()
      }
    },
    createFGF() {
      this.data.startdate = moment(this.data.date[0]).format('yyyy-MM-DD')
      this.data.expiredate = moment(this.data.date[1]).format('yyyy-MM-DD')
      this.onCreate(this.data)
    },
    onImgFileChange(e) {
      let type = ['image/png', 'image/jpeg']
      let currentsize = e.target.files[0].size / (1024 * 1024) < 10
      const _URL = window.URL || window.webkitURL
      let img, width, height
      let objectUrl = _URL.createObjectURL(e.target.files[0])
      img = new Image()
      let vmthis = this
      img.onload = function () {
        width = this.width
        height = this.height
        if (
          width == 1200 &&
          height == 800 &&
          type.includes(e.target.files[0].type) &&
          currentsize
        ) {
          vmthis.data.image = e.target.files[0]
          var afterDot = vmthis.data.image.name.substr(
            0,
            vmthis.data.image.name.indexOf('.'),
          )
          var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
          var newFileName = nonSpacial.replace(/\s/g, '_')
          var resultFile = new File([vmthis.data.image], newFileName, {
            type: vmthis.data.image.type,
          })
          vmthis.imgPreview = URL.createObjectURL(resultFile)
        } else {
          vmthis.onActionUploadModal(true)
        }
        _URL.revokeObjectURL(objectUrl)
      }
      img.src = objectUrl
    },
    showTableList(value) {
      console.log('showTableList :: ', value)
      Account.bzbsAnalyticTracking(
        'get_friend_page',
        'get_friend',
        'click_get_friend_' + value.title + '_box',
        'on click',
      )
      if (value.rowKey) this.rowKey = value.rowKey

      if (value.key == 'list-newuser') {
        this.isModalNewUser = true
      } else if (value.key == 'list-inviter') {
        this.isModalInviter = true
      } else if (value.key == 'list-invited-people') {
        this.isModalInvitedPeople = true
      }
    },
    closeModal(event) {
      this.isModalNewUser = event
      this.isModalInviter = event
      this.isModalInvitedPeople = event
    },
  },
}
</script>

<style lang="scss" scoped>
.mainBtn {
  font-size: 16px;
  font-weight: 500;
  max-width: 298px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #ff9800;
  color: #ff9800;
  background-color: white;
  &:disabled {
    color: #ccc;
    border: solid 1px #ccc;
    path {
      fill: #ccc;
    }
  }
  &.sec {
    color: white;
    background-color: #ff9800;
    &:disabled {
      background: #ccc;
      border: solid 1px #ccc;
    }
  }
}
.lineBtn {
  color: white;
  background-color: #22b950;
  height: 38px;
  width: 100%;
  border: 1px solid #22b950;
  cursor: default;
}
.dotAfter {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dotAfter4 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  .title {
    font-size: 12px;
    color: #bdbdbd;
    .main {
      font-size: 20px;
      font-weight: 500;
      color: #424242;
      margin-right: 8px;
    }
  }
  .activeFGF {
    font-size: 20px;
    font-weight: 500;
    color: #424242;
    margin-right: 8px;
    button {
      margin-bottom: 3px;
    }
  }
}
.stat {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  margin: 30px 0 60px 0;
  .statsBox {
    width: 220px;
    min-height: 126px;
    border-radius: 4px;
    background-color: white;
    border: solid 1px #bdbdbd;
    cursor: pointer;

    .title {
      border-bottom: 1px solid #fafafa;
      padding: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #424242;
      display: flex;
      span {
        margin-right: 8px;
        border-radius: 20px;
        padding: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 19px;
          height: 19px;
        }
      }
    }
    .value {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .number {
        font-size: 24px;
        margin-right: 8px;
        &.join {
          color: #4caf50;
        }
        &.newuser {
          color: #2196f3;
        }
        &.pointspendInvited,
        &.pointspendInviter {
          color: #f57c00;
        }
      }
      .unit {
        margin-right: auto;
      }
      svg {
        margin-left: auto;
      }
    }
  }
}
.mainContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 30px;
  .mainInputForm {
    .nFlex {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 67px;
      margin-bottom: 45px;
    }
    .label {
      padding-top: 10px;
      width: 200px;
      font-size: 16px;
      font-weight: 600;
      color: #424242;
      text-align: right;
    }
    .mainInput {
      width: 622px;
      input,
      textarea {
        width: 100%;
        padding: 10px;
        color: #616161;
        font-size: 16px;
        border-radius: 4px;
        border: solid 1px #bdbdbd;
        &:focus {
          outline: none;
        }
      }
      .totalText {
        text-align: right;
        &.invite {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .imgCover {
      .mainInput {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        .previewImg {
          max-width: 300px;
          width: 100%;
          height: 300px;
          border: 1px solid #ccc;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .upload {
          div:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }
    .referal {
      .mainInput {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 14px;
        .flexwarp {
          flex-wrap: wrap;
          gap: 8px;
        }
        input {
          max-width: 156px;
        }
        .label {
          margin-right: 30px;
        }
        .unit {
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }
    .maxPoint {
      .mainInput {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        input {
          width: 202px;
        }
      }
    }
    .message {
      position: relative;
      .cleartext {
        font-size: 16px;
        font-weight: 500;
        color: #2196f3;
        position: absolute;
        top: 0;
        right: -50px;
        cursor: pointer;
      }
    }
    .condition {
      .conditioncontainer {
        padding-top: 10px;
        display: flex;
        align-items: center;
        input {
          width: 124px;
          text-align: right;
          &.error {
            border: 1px solid red;
          }
        }
      }
    }
  }
  .mainPreviewContainer {
    .previewText {
      text-align: center;
      font-size: 24px;
      color: #424242;
      margin-bottom: 16px;
    }
    .imgContainer {
      border-radius: 50px;
      overflow: hidden;
      max-width: 347px;
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .previewMaincontainer {
        position: absolute;
        top: 8%;
        left: 4.6%;
        width: 92%;
        background-color: white;
        .storeName {
          color: white;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #b9c3d0;
          height: 39px;
        }
        .previewImg {
          height: 178px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .details {
          text-align: center;
          padding: 8px 6px;
          height: 150px;
          > * {
            padding: 8px 6px;
          }
          .title {
            font-weight: bold;
          }
          .subdetail {
            font-size: 11px;
            color: #8b8b8b;
            padding-bottom: 0px;
            margin-bottom: 8px;
          }
          .date {
            font-size: 10px;
            color: #d4d4d4;
          }
        }
        .invite {
          background-color: #fafafa;
          height: 225px;
          padding: 15px 6px;
          .header {
            display: flex;
            justify-content: space-between;
            .title {
              font-weight: bold;
              color: black;
              font-size: 14px;
            }
            .subtitle {
              font-size: 10px;
              color: #d4d4d4;
            }
          }
          .lineBtnCintainer {
            margin-top: 10px;
          }
          .link {
            margin-top: 50px;
            .text {
              text-align: center;
              font-size: 12px;
              color: rgb(39, 39, 39);
            }
            .linkContainer {
              .linktext {
                height: 38px;
                width: 100%;
                background-color: white;
                border: 1px solid black;
                position: relative;
                padding: 5px 50px 5px 5px;
                button {
                  background-color: #033a70;
                  border: none;
                  color: white;
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.fontcolor {
  color: #616161;
}
.maxwidth250px {
  max-width: 250px;
}
.totextlable {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #424242;
}
.radiogrup {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (max-width: 959px) {
  .mainContainer {
    .mainInputForm {
      .mainInput {
        max-width: 622px;
        width: 100%;
      }
      .nFlex {
        gap: 30px;
        justify-content: flex-start;
        .label {
          text-align: left;
        }
      }
    }
  }
  .mainContainer .mainInputForm .message .cleartext {
    right: 25px;
  }
}
@media only screen and (max-width: 570px) {
  .totextlable {
    padding-right: 50px !important;
  }
}
</style>
<style lang="scss">
.mainInput .ant-calendar-picker {
  width: 40%;
}
.mainInput .ant-calendar-picker-input.ant-input {
  width: 100%;
  height: 46px;
  border: solid 1px #bdbdbd;
}
.mainInput .ant-radio-inner::after {
  background-color: #ff9800;
}
.mainInput .ant-radio-checked .ant-radio-inner {
  border: 1px solid #ff9800;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff9800;
  border-color: #ff9800;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff9800;
}
.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
</style>
