<template>
  <div class="fgf-modal-newuser">
    <a-modal
      v-model="showModal"
      :footer="null"
      class="modal-FGFNewuser"
      :class="[currentLocale]"
      :afterClose="modalClose"
      destroyOnClose
    >
      <div class="modal-header">
        <h5 class="modal-title">
          {{ lbl['fgf-newuser'] }}
        </h5>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="div-import pt-3">
            <ExportExcel
              :text="lbl['dashboard-insight-export-excel-button']"
              @clickButton="exportExcel()"
            />
          </div>
        </div>
      </div>
      <a-table
        row-key="userId"
        :columns="columns"
        :data-source="data"
        :pagination="{
          pageSize: pageSize,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
          pageSizeOptions: pageSizeOptions,
          defaultCurrent: 1,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onChange: onChangePage,
        }"
        class="pt-3"
      >
        <template slot="isdata" slot-scope="text">
          {{ text ? text : '-' }}
        </template>
        <template slot="isdata_date" slot-scope="text">
          {{ text ? dateShortFormatUTC(text) : '-' }}
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import XLSX from 'xlsx'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import Account from '@/helper/AccountHelper.js'
import ExportExcel from '@/module/components/dashboard/botton/ExportExcel'
import BzbsUserFGF from '@/core/FriendgetFriends/service/BzbsFriendgetFriends'

export default {
  mixins: [Mixin],
  components: {
    ExportExcel,
  },
  props: {
    show: Boolean,
    rowKey: String,
  },
  data() {
    return {
      data: [],
      total: null,
      current: 0,
      userId: 0,
      showModal: true,
      pageSize: 20,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      currentLocale: 'th',
      columns: [
        {
          title: 'UserID',
          dataIndex: 'UserId',
          key: 'dashboard-insight-user-id-header',
          align: 'left',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'user-permissions-name',
          align: 'left',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Mobile Number',
          dataIndex: 'ContactNumber',
          key: 'user-role-list-mobile-number',
          align: 'center',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'user-role-manage-email',
          align: 'left',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Date',
          dataIndex: 'Timestamp',
          key: 'dashboard-transactions-date-time',
          align: 'center',
          scopedSlots: {
            customRender: 'isdata_date',
          },
        },
      ],
    }
  },
  created() {
    this.setLocale()
    this.init()
  },
  mounted() {
    this.currentLocale = Locale.getLocaleShort()
  },
  methods: {
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
    },
    init() {
      this.getDetailsList()
    },
    getDetailsList() {
      return new Promise(resolve => {
        BzbsUserFGF.getDetailsNewUser(this.rowKey)
          .then(res => {
            if (res.data.success) {
              this.data = res.data.data
            } else {
              this.data = []
            }
            resolve(res.data)
          })
          .catch(error => {
            this.data = []
            resolve(error)
          })
      })
    },
    onChangePage(page) {
      this.current = page
    },
    modalClose() {
      this.showModal = false
      Account.bzbsAnalyticTracking(
        'FGF_page',
        'FGF_details',
        'click_modal_close',
        'on click',
      )
      this.$emit('closeModal', false)
    },
    exportExcel() {
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'fgf_page',
        'fgf_details',
        'click_fgf_download',
        'on click',
      )

      var data = _.map(this.data, item => {
        var user_id = this.lbl['dashboard-insight-user-id-header']
        var name = this.lbl['user-permissions-name']
        var mobile_number = this.lbl['user-role-list-mobile-number']
        var email = this.lbl['user-role-manage-email']
        var date = this.lbl['dashboard-transactions-date-time']

        let time = ''
        if(item.Timestamp) {
          time = this.dateShortFormatUTC(item.Timestamp)
        }

        return {
          [user_id]: item.UserId ? item.UserId : '-',
          [name]: item.Name ? item.Name : '-',
          [mobile_number]: item.ContactNumber ? item.ContactNumber : '-',
          [email]: item.Email ? item.Email : '-',
          [date]: time ? time : '-',
        }
      })

      if (data.length <= 10000) {
        var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
        var wscols = [
          { wch: 40 },
          { wch: 20 },
          { wch: 12 },
          { wch: 20 },
          { wch: 12 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          var ws_cols = [
            { wch: 40 },
            { wch: 20 },
            { wch: 12 },
            { wch: 20 },
            { wch: 12 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }

      this.handleLoading(false)
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/common.scss';
.modal-FGFNewuser {
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;

    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: $color-orange-7e;
  }

  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .div-import {
    float: right;
    margin-right: 4px;
  }

  .modal-header {
    padding: 0rem 1rem 1rem 1rem !important;
    border-bottom: 1px solid $color-grey-e4;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 568px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }

  //แนวนอน mobile (iPhone x)
  @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ant-modal {
      width: 90% !important;
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1023px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }
}
.modal-FGFNewuser .ant-pagination-next .ant-pagination-item-link,
.modal-FGFNewuser .ant-pagination-prev .ant-pagination-item-link {
  border-color: $border;
  color: $color-black-b3;
}
</style>
