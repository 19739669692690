var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fgf-modal-invitedpeople"},[_c('a-modal',{staticClass:"modal-InvitedPeople",class:[_vm.currentLocale],attrs:{"footer":null,"afterClose":_vm.modalClose,"destroyOnClose":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.lbl['fgf-pointspendInvited'])+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"div-import pt-3"},[_c('ExportExcel',{attrs:{"text":_vm.lbl['dashboard-insight-export-excel-button']},on:{"clickButton":function($event){return _vm.exportExcel()}}})],1)])]),_c('a-table',{staticClass:"pt-3",attrs:{"row-key":"userId","columns":_vm.columns,"data-source":_vm.data,"pagination":{
        pageSize: _vm.pageSize,
        showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
        pageSizeOptions: _vm.pageSizeOptions,
        defaultCurrent: 1,
        showSizeChanger: true,
        hideOnSinglePage: false,
        onChange: _vm.onChangePage,
      }},scopedSlots:_vm._u([{key:"isdata",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : '-')+" ")]}},{key:"isdata_date",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.dateShortFormatUTC(text) : '-')+" ")]}},{key:"isdata_point",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : '0')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }