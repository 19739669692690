<template>
  <div id="export_excel_button">
    <a-button class="export-excel-button" @click="clickButton()">
      <img
        class="icon"
        :src="require('@/assets/images/icon-excel.svg')"
        alt=""
      />
      <span class="text">{{ text }}</span>
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'ExportExcel',
  props: {
    text: {
      type: String,
      default: 'Export Excel',
    },
  },
  methods: {
    clickButton() {
      this.$emit('clickButton')
    },
  },
}
</script>

<style lang="scss" scoped>
.export-excel-button {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 6px;
  border: solid 1px #389e0d !important;
  background-color: #ffffff !important;
  color: #389e0d !important;

  .icon {
    height: 20px;
    margin-right: 8px;
  }
  .text {
    font-size: 16px;
    line-height: 0;
  }
}
.export-excel-button:hover {
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}
</style>
