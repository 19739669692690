import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
// import moment from 'moment'
/**
 * @method getCRMPlusStaff
 * @method getCRMPlusAdmin
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  agency_id: function () {
    return Cache.get('CRMUser').AgencyId
  },
  getActiveFGF() {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/detail'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getActiveFGFByRowKey(rowkey) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      rowkey
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/publish'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getHistoryFGF() {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/history'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  changeStatus(rowkey, mode) {
    let modePath = ''
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      rowkey,
    }
    if (mode === 'toActive') {
      modePath = 'publish'
    } else {
      modePath = 'cancel_publish'
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/' + modePath
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  createFGF(data) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      ...data,
    }
   
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/create'

    if(!data.image){
      console.log(1)
      return BzbsApi.connectBzbsPostParams(strUrl, this.token(), params)
    }else{
      return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
    }
  },
  updateFGF(data) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      ...data,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/update'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  upLoadImg(image) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      image,
    }
    var strUrl = this.config().bzbsEdmUrl + '/campaign/artwork'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getDetailsNewUser(key) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      key: key
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusfriendgetfriend/details'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
}
