<template>
  <div :class="['mainBG', isOpenModal ? 'open' : '']">
    <div class="mainModal">
      <div class="title">
        <div class="icon">
          <img
            src="@/assets/images/Warning-1-circle.svg"
            alt=""
            width="24"
            height="24"
          />
        </div>
        <div class="titleText">{{ title }}</div>
      </div>
      <div class="details">{{ details }}</div>
      <div class="btnContainer">
        <button
          id="friendgetfriends_fgfmodal_onactionmodalback_button"
          @click="onActionModal(false, false, type)"
          class="outlineprimary"
          v-if="showbackto"
        >
          {{ backto }}
        </button>
        <button
          id="friendgetfriends_fgfmodal_onactionmodalconfirm_button"
          @click="onActionModal(false, true, type)"
          class="primarybtn"
        >
          {{ lbl['fgf-confirm'] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    title: {
      type: String,
    },
    details: {
      type: String,
    },
    backto: {
      type: String,
    },
    type: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
    },
    showbackto: {
      type: Boolean,
      default:true
    },
    onActionModal: {
      type: Function,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.mainBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 256;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 530px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    .title {
      display: flex;
      .icon {
        width: 44px;
        height: 44px;
        padding: 10px;
        border-radius: 4px;
        background-color: #fff3e0;
      }
      .titleText {
        margin-left: 8px;
        font-size: 24px;
      }
    }
    .details {
      margin-top: 10px;
      font-size: 14px;
    }
    .btnContainer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      .main {
        background-color: #ff9800;
        border-radius: 4px;
        height: 36px;
        border: 1px solid #ff9800;
        font-size: 16px;
        color: white;
        padding: 0 16px;
        font-weight: 500;
        &.sec {
          color: #ff9800;
          background-color: white;
        }
      }
    }
  }
}
</style>
